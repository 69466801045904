import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import addToMailchimp from 'gatsby-plugin-mailchimp';

const NewsletterForm = () => {
  const { register, handleSubmit, clearErrors, reset } = useForm();
  const [result, setResult] = useState(null);

  const onSubmit = (data, e) => {
    e.preventDefault();
    const { email } = data;

    addToMailchimp(email)
      .then(() => {
        setResult('success');
        setTimeout(() => {
          reset();
          clearErrors();
          setResult(null);
        }, 3000);
      })
      .catch(() => {
        setResult('error');
      });
  };

  const checkMarkResult = () => {
    if (result === 'success') {
      return 'is-valid';
    } else if (result === 'error') {
      return 'is-invalid';
    } else {
      return;
    }
  };

  const renderHelperText = () => {
    if (result === 'success') {
      return (
        <div className="valid-feedback text-left">
          Check your email to confirm your subscription.
        </div>
      );
    } else if (result === 'error') {
      return (
        <div
          id="validationServer03Feedback"
          className="invalid-feedback text-left"
        >
          Something went wrong.
        </div>
      );
    } else {
      return;
    }
  };

  return (
    <section className="pt-0 ">
      <div className="container">
        <div className="section-heading text-center">
          <h2>Join our Newsletter</h2>
          <p>
            Be the first to know about the newest updates and the exciting
            campaigns we run in Gust
          </p>
        </div>
        <div className="row pt-3">
          <div className="col">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="input-group col-md-6 mx-auto">
                <input
                  name="email"
                  type="email"
                  className={`form-control ${checkMarkResult()}`}
                  id="inputEmail3"
                  placeholder="Enter email to subscribe"
                  aria-label="Email"
                  aria-describedby="send-email"
                  ref={register}
                  disabled={result === 'success'}
                  required
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-secondary"
                    type="submit"
                    id="send-email"
                    data-toggle="button"
                    aria-pressed="false"
                  >
                    submit
                  </button>
                </div>
                {renderHelperText()}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsletterForm;
