import React from 'react';
import Social from '../components/Social';
import NewsletterForm from '../components/NewsletterForm';

const Contact = () => {
  return (
    <section className="contact bg-primary" id="contact">
      <NewsletterForm />
      <div className="container">
        <div className="section-heading text-center">
          <h2>Contact us</h2>
          <Social />
        </div>
      </div>
    </section>
  );
};

export default Contact;
