import React from 'react';
import CustomBlog from './CustomBlog';

export const MoreSection = () => {
  return (
    <section id="more" className="pt-5 pb-0">
      <CustomBlog />
    </section>
  );
};

export default MoreSection;
