import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import AccordionItem from '../components/AccordionItem';

const FAQ = props => {
  const { data } = props;
  const { edges: faqItems } = data.allMarkdownRemark;
  return (
    <section className="faq faq-style-one" id="faq">
      <div className="container">
        <div className="section-heading text-center">
          <h2>Frequently Asked Questions</h2>
          <p>Find answers to the questions we get asked the most!</p>
          <hr />
        </div>
        <div className="row">
          <div className="container-fluid">
            <div className="accordion" id="accordion">
              <div className="accrodion-grp faq-accrodion">
                {faqItems &&
                  faqItems.map(({ node: item }) => (
                    <AccordionItem
                      key={`accordion-card-${item.id}`}
                      status={false}
                      title={item.frontmatter.question}
                      content={item.frontmatter.answer}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FAQ.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query FAQQuery {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "faq" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                question
                answer
                date(formatString: "MMMM DD, YYYY")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <FAQ data={data} count={count} />}
  />
);
