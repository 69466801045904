import React from 'react';
import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const Features = () => {
  return (
    <section className="features" id="features">
      <div className="container">
        <div className="section-heading text-center">
          <h2>
            Monetize your social followings and take full control over the
            campaigns YOU want to participate in.
          </h2>
          <p className="text-muted">
            Want to be part of Gust? Simply{' '}
            <span>
              <Link
                to="/request-account"
                className="text-warning stretched-link section1-link"
              >
                request a Gust account
              </Link>
            </span>{' '}
            through our website or within{' '}
            <span>
              <AnchorLink
                className="text-warning stretched-link underline-link"
                to="/#download"
                stripHash={true}
              >
                the app
              </AnchorLink>
            </span>
            {'.'}
          </p>
          <hr />
        </div>
        <div className="row">
          <div className="col-lg-4 my-auto">
            <div className="device-container">
              <div>
                <div className="device">
                  <div className="screen">
                    <img
                      src={'https://assets.gust.io/gust.webp'}
                      className="img-fluid"
                      alt="Gust"
                    />
                  </div>
                  <div className="button" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 my-auto">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-screen-smartphone text-primary" />
                    <h3>View Campaigns</h3>
                    <p className="text-muted">
                      View all available campaigns on our app and participate in
                      the ones that you think will resonate with your audience
                      to maximize your earnings.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-camera text-primary" />
                    <h3>Download Content</h3>
                    <p className="text-muted">
                      Easily download campaign content to your mobile device
                      from the Gust app. Creators who want to SUBMIT custom
                      content can easily upload it on the app for quick approval
                      before posting.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-present text-primary" />
                    <h3>Track Earnings</h3>
                    <p className="text-muted">
                      Track all your earnings and bonuses, view your transaction
                      history and cash out anytime directly on the app (we
                      support Paypal, Venmo and Transferwise).
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="feature-item">
                    <i className="icon-lock-open text-primary" />
                    <h3>Support Chat and Push Notifications</h3>
                    <p className="text-muted">
                      Chat with our support team and receive push notifications
                      in real time for new campaigns, payments and updates to
                      help you stay in the loop for the exciting things we are
                      working on.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
