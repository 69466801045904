import React from 'react';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';

const Banner = () => {
  return (
    <header className="masthead" id="home">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-lg-7 my-auto">
            <div className="header-content mx-auto">
              <h1 className="mb-5">
                GUST- a simple way to connect Influencers like you to today’s
                trending brands.
              </h1>
              <p className="mb-5">
                Ready to make money? Gust is the simplest and quickest way to
                monetize your followings. We have a growing network of hundreds
                of social users with 300 million followers (and counting).
              </p>
              <div className="row buttons">
                <div className="col-md-auto">
                  <Link
                    to="/request-account/"
                    className="btn btn-outline btn-xl"
                  >
                    Request Influencer Account
                  </Link>
                </div>
                <div className="col-md-auto d-flex align-items-center justify-content-center">
                  <OutboundLink
                    href="https://gustprogram.com/get-started/"
                    className="text-warning stretched-link section1-link"
                  >
                    <u>I am an Advertiser</u>
                  </OutboundLink>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 my-auto">
            <div className="device-container">
              <div className="device">
                <div className="screen">
                  <img
                    src="https://assets.gust.io/gust.webp"
                    className="img-fluid"
                    alt="Gust"
                  />
                </div>
                <div className="button" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Banner;
