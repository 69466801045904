import React, { useState } from 'react';

const AccordionItem = ({ status, title, content }) => {
  const [accordionStatus, setAccordionStatus] = useState(status);
  const handleClick = () => {
    setAccordionStatus(!accordionStatus);
  };
  return (
    <div className={`accrodion ${accordionStatus === true ? 'active' : ''}`}>
      <div className="accrodion-inner">
        <div
          className="accrodion-title"
          onClick={handleClick}
          onKeyDown={handleClick}
        >
          <h4>{title}</h4>
        </div>
        {accordionStatus === true ? (
          <div className="accrodion-content">
            <div className="inner">
              <p>{content}</p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AccordionItem;
