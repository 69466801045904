import React from 'react';
import Layout from '../components/Layout';
import Header from '../components/Header';
import Banner from '../components/Banner';
import Features from '../components/Features';
import CTAOne from '../components/CTAOne';
import Brands from '../components/Brands';
import FAQ from '../components/FAQ';
import MoreSection from '../components/MoreSection';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import SEO from '../components/SEO';

const IndexPage = props => {
  const { location } = props;
  const currentLocation = location.pathname;
  return (
    <Layout>
      <SEO title="Home" />
      <Header currentLocation={currentLocation} />
      <Banner />
      <Features />
      <CTAOne />
      <Brands />
      <FAQ />
      <MoreSection />
      <Contact />
      <Footer />
    </Layout>
  );
};

export default IndexPage;
