import React from 'react';
import { OutboundLink } from 'gatsby-plugin-gtag';
import gplay from '../assets/images/google-play-badge.svg';
import astore from '../assets/images/app-store-badge.svg';

const CTAOne = () => {
  return (
    <section className="download bg-primary text-center" id="download">
      <div className="container">
        <div className="row">
          <div className="col-md-8 mx-auto">
            <h2 className="section-heading">
              Discover what all the buzz is about!
            </h2>
            <p>
              Our app is available on any mobile device! Download now to get
              started!
            </p>
            <div className="badges">
              <OutboundLink
                id="google-play-button"
                className="badge-link"
                href="https://play.google.com/store/apps/details?id=com.gust.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={gplay} alt="Gust Android App" />
              </OutboundLink>
              <OutboundLink
                id="app-store-button"
                className="badge-link"
                href="https://apps.apple.com/us/app/gust/id1508204799"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={astore} alt="Gust iOS App" />
              </OutboundLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTAOne;
