module.exports = {
  siteTitle: 'Gust App',
  manifestName: 'gust',
  manifestShortName: 'Landing',
  manifestStartUrl: '/',
  manifestBackgroundColor: '#fff',
  manifestThemeColor: '#fff',
  manifestDisplay: 'standalone',
  manifestIcon: './src/assets/img/favicon.png',
  pathPrefix: `/gust/`,
  description:
    'Experience the freedom to view and accept advertisement opportunities from Gust',
  author: `@gust.io`,
  logo: 'https://assets.gust.io/logo_black.webp',
  siteUrl: 'https://gust.io',

  socialLinks: [
    {
      icon: 'fas fa-envelope',
      name: 'Email',
      url: 'mailto:contact@gust.io',
    },
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/thegustapp',
    },
    {
      icon: 'fa-facebook-f',
      name: 'Facebook',
      url: 'https://www.facebook.com/gustmarketing/',
    },
  ],
  measurementId: `G-LZZVLKY5XE`,
  mailchimpEndpoint:
    'https://socialcoaster.us16.list-manage.com/subscribe/post?u=032e46172752ce8da583c2b63&amp;id=34210e0eb1',
};
