import React from 'react';
import BRANDS_DATA from '../data/brands.json';

const Brands = () => {
  return (
    <section className="brands" id="brands">
      <div className="container">
        <div className="row brands__row">
          <div className="col-sm-12 col-md-12 col-lg-2 title">
            <span>Trusted by</span>
          </div>
          {BRANDS_DATA.map(({ logo, alt }, index) => (
            <div
              key={index}
              className="col-sm-4 col-md-6 col-lg-2 logo-container"
            >
              <img src={logo} alt={alt} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Brands;
