import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';

const CustomBlog = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;

  return (
    <section id="blog" className="pt-5 pb-3">
      <div className="container">
        <div className="section-heading text-center mb-5">
          <h2>Want more ?</h2>
          <p className="text-muted">Checkout the latest posts on our blog</p>
          <hr />
        </div>
        <div className="card-columns-wrapper custom-blog">
          {posts &&
            posts.map(post => (
              <div key={post.node.id} className="mb-5">
                <div className="card card-post" id="card-latest-post___list">
                  {post.node.frontmatter.featuredImage ? (
                    <div className="featured-thumbnail">
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: post.node.frontmatter.featuredImage,
                          alt: `featured image thumbnail for post ${post.node.frontmatter.title}`,
                        }}
                      />
                    </div>
                  ) : null}
                  <div className="card-body card___content">
                    <h5 className="card-title title">
                      {post.node.frontmatter.title}
                    </h5>
                    <p className="card-text post-card-excerpt">
                      {post.node.excerpt}
                    </p>
                    <Link className="button" to={post.node.fields.slug}>
                      Keep Reading →
                    </Link>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="section-heading text-center mb-5">
          <Link className="btn btn-link nav-link go-blog-link" to="/blog">
            Go to blog →
          </Link>
        </div>
      </div>
    </section>
  );
};

CustomBlog.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogLatestPostsQuery {
        allMarkdownRemark(
          limit: 2
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 100)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredImage {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <CustomBlog data={data} count={count} />}
  />
);
